<template>
<div id="about">
  <div class="introductPic">
    <span>哈密市人才集团有限公司</span>
  </div>
  <div class="introduct">
    <div class="introductWrap">
       <h3>公司简介</h3>
       <p>哈密市人才集团有限公司 (以下简称哈密人才集团)，注册资金2000万元。哈密市人才集团有限公司是哈密唯家从事人才服务的国有独资公司，聚焦 人力资源引进招聘、教育培训、人力资源综合服务、劳务派遣、园区运营、信息平台建设等业务，为哈密经济高质量发展提供人力资源支持</p>
       <div class="picBox">
         <div class="picTop">
            <div class="pic-item" style="width:590px" @mouseover="mouseover(1)" @mouseout="mouseout()">
               <img width="590px" src="@/assets/imgs/company/introOne.png" />
               <div class="mask" v-show="subMask==1">
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div>
            </div>
            <div class="pic-item" style="width:287px">
                <img width="287px" src="@/assets/imgs/company/introTwo.png" />
               <!-- <div class="mask">
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div> -->
            </div>
            <div class="pic-item" style="width:287px;margin-right:0">
                <img width="287px" src="@/assets/imgs/company/introTwo.png" />
               <!-- <div class="mask" >
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div> -->
            </div>
         </div>
         <div class="picTop">
            <div class="pic-item" style="width:287px">
               <img width="287px" src="@/assets/imgs/company/introFour.png" />
               <!-- <div class="mask">
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div> -->
            </div>
            <div class="pic-item" style="width:287px">
                <img width="287px" src="@/assets/imgs/company/introTwo.png" />
               <!-- <div class="mask">
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div> -->
            </div>
            <div class="pic-item" style="width:590px;margin-right:0" @mouseover="mouseover(2)" @mouseout="mouseout()">
                <img width="590px" src="@/assets/imgs/company/introOne.png" />
               <div class="mask" v-show="subMask==2">
                  <img src="@/assets/imgs/company/introLocation.png" >
                  <p>哈密市人才集团有限公司（国有独资）坐落于新疆哈密市伊州区 绿洲大道111号哈密高新技术产业开发区</p>
               </div>
            </div>
         </div>
       </div>
       <h3>发展思路</h3>
       <p>栽下梧桐树，引来金凤凰。哈密市能源资源禀赋、产业基础和区位优势突出，加快培养构建以现代化煤化工、新能源、新材料、装备制造、现代物流、 文化旅游六大产业为主导的富有哈密特色的现代化产业体系。 </p>
       <p>哈密市人才集团有限公司已为企业和人才搭建起了“选、用、育、留”的简洁高效、智慧数字化线上线下服务平台，我们用最饱满的热情，最优质 的服务，热忱欢迎全国各地企业和人才来哈密共创发展。</p>
    </div>
    <div class="tips">
      <ul>
        <li>扩容提质</li>
        <li>创新赋能</li>
        <li>搭台引智</li>
        <li>聚才兴哈</li>
      </ul>
    </div>
    <div class="position">
      <div class="positionWrap">
         <h3>公司定位</h3>
         <p>哈密人才集团着力打造哈密市人力资源产业孵化基地，秉持“扩容提质、创新赋能、搭台引智、聚才兴哈”的发展思路，按照“政府主导、市场运作、 企业运营”的发展模式，探索出一套“全生态产业链、全生命周期服务、全数字化驱动、全域覆盖”的市场化人才服务模式。承接哈密市组织部人才引 进工作，立足全市、辐射周边、面向全国，围绕推动项目建设、增强科技创新、用活现代金融、壮大人力资源进行布局，聚焦人才引进招聘、人才教育 培训、人才综合服务、人才劳务派遣、人才园区运营、人才信息平台建设等六大业务板块，努力打造成服务优、机制活、效益好、竞争力强、影响力大 的人才发展投资运营服务品牌和标杆企业，成为哈密市经济社会高质量发展的“新引擎”“新动能”。</p>
      </div>
    </div>
    <div class="build">
      <div class="buildWrap">
        <h3>公司组织架构图</h3>
        <div class="director">
           <span>董事会</span>
           <i></i>
        </div>

        <div class="subordinate">
           <div class="line"></div>
           <ul>
             <li>
               <i></i>
               <span>综合管理部</span>
             </li>
             <li>
               <i></i>
               <span>创业服务部</span>
             </li>
             <li>
               <i></i>
               <span>劳务派遣部</span>
             </li>
             <li>
               <i></i>
               <span>人力资源 开发部</span>
             </li>
             <li>
               <i></i>
               <span>培训教育部</span>
             </li>
             <li>
               <i></i>
               <span>财务审计部</span>
             </li>
             <li>
               <i></i>
               <span>数据资产部</span>
             </li>
           </ul>
        </div>
      </div>
    </div>
    <div class="plate">
       <div class="plateWrap">
         <h3>六大业务板块</h3>
         <ul>
           <li>人才引进招聘</li>
           <li>人才教育培训</li>
           <li>人才综合服务</li>
           <li>人才劳务派遣</li>
           <li>人才园区运营</li>
           <li>人才信息平台建设</li>
         </ul>
       </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      subMask:''
    }
  },
  mounted() {
    
  },
  methods: {
   mouseover(index){
    this.subMask = index
   },
   mouseout(){
    this.subMask = ''
   }
  }
};
</script>

<style lang="scss" scoped>
.introductPic{
  height: 234px;
  text-align: center;
  background:url('../assets/imgs/company/about.png');
  background-size: cover;
  span{
    color: #fff;
    font-size: 30px;
    position: relative;
    letter-spacing: 1px;
    top: 90px;
    
  }
}
.introduct{
  padding-top: 74px;
  background: #D9EAFE;
  .introductWrap{
   width: 1200px;
   min-height: 300px;
   margin: 0 auto;
  }
  h3{
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    line-height: 60px;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    color: #222222;
    line-height: 46px;
  }
  .picBox{
    clear: both;
    margin-top: 25px;
    margin-bottom: 35px;
    img{
      height: 203px;
    }
    .picTop{
      margin-top: 20px;
      width: 1220px;
      overflow: hidden;
    }
    .pic-item{
      position: relative;
      cursor: pointer;
      float: left;
      margin-right: 20px;
      .mask{
        position: absolute;
        top: 0;
        background: #0077FF;
        width: 100%;
        height: 100%;
        text-align: center;
        img{
          width: 40px;
          height: 44px;
          margin-top: 34px;
        }
        p{
          color: #fff;
          height: 56px;
          font-size: 18px;
          color: #FFFFFF;
          line-height: 38px;
          margin: 20px;
          text-align: left;
        }
      }
    }
  }
}
.tips{
  width: 1200px;
  margin:0 auto;
  position: relative;
  top: 80px;
  li{
    width: 253px;
    height: 165px;
    line-height: 165px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #E9EEFF;
    border-radius: 20px;
    float: left;
    margin-left: 45px;
    font-size: 24px;
    font-weight: bold;
    color: #222222;
  }
}
.position{
  background: #F3F9FF;
  clear: both;
  padding-top: 140px;
  padding-bottom: 40px;
  .positionWrap{
      width: 1200px;
      min-height: 300px;
      margin: 0 auto;
    h3{
      font-size: 24px;
      font-weight: bold;
      color: #222222;
      line-height: 60px;
    }
    p{
      font-size: 18px;
      font-weight: 400;
      color: #222222;
      line-height: 46px;
    }
  }
}
.build{
   background: #EEF2F7;
  clear: both;
  overflow: hidden;
  padding-bottom: 74px;
  .buildWrap{
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 60px;
    h3{
      font-size: 24px;
      font-weight: bold;
      color: #222222;
      line-height: 60px;
    }
  }
}
.director{
  margin-left: 511px;
  width: 123px;
  height: 123px;
  background: #C5DEF8;
  border-radius: 50%;
  text-align: center;
  line-height: 123px;
  position: relative;
  top: 16px;
  span{
    display: inline-block;
    width: 104px;
    height: 102px;
    background: #0077FF;
    border-radius: 50%;
    text-align: center;
    line-height: 104px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 11px;
  }
  i{
    width: 1px;
    display: block;
    height: 85px;
    margin-left: 62px;
    background: #0077FF;
    margin-top: -5px;
  }
}
.subordinate{
  clear: both;
  margin-top: 100px;
  .line{
     background: #0077FF;
     height: 1px;
     width: 1028px;
     margin-left: 60px;
  }
  li{
   
    float: left;
    margin-right: 50px;
     i{
        width: 1px;
        display: block;
        height: 85px;    
        background: #0077FF;
        margin-left: 60px;
      }
      span{
          float: left;
          width: 121px;
          line-height: 122px;
          height: 122px;
          text-align: center;
          font-size: 16px;
          color: #FFFFFF;
          background: #0077FF;
          box-shadow: 0px 1px 9px 1px rgba(0,119,255,0.3);
          border-radius: 50%;
      }
  }
}
.plate{
  background: #F3F9FF;
  clear: both;
  overflow: hidden;
  padding-bottom: 74px;
  .plateWrap{
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 60px;
    h3{
      margin-bottom: 30px;
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }
    li{
      width: 376px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 1px 9px 1px rgba(193,222,255,0.3);
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #222222;
      float: left;
      margin-right: 24px;
      margin-bottom: 20px;
    }
  }
}
</style>

